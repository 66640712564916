import React from "react";
import "./Contact.css";
import { ContactData } from "../../data";
import NinthSec from "../NinthSec";
import SecSec from "../SecSec";
import ContactThird from "./Contact-Third/ContactThird";
import Form from "./Form/Form";
import ContactThirdSec from "./Third-Sec/Contact-Third-Sec";
import ContactForthSec from "./Forth-Sec/Contact-Forth-Sec";

export default function Contact() {
  const content = ContactData[0];

  return (
    <div className="contact flex flex-col justify-between">
      <NinthSec
        backgroundImage={content.Hero.backgroundImage}
        spans={content.Hero.spans}
        p={content.Hero.p}
      />
      <SecSec />
      <ContactThird
        backGroundImg={content.ThirdSec.backGroundImg}
        p1={content.ThirdSec.p1}
        h1={content.ThirdSec.h1}
        p2={content.ThirdSec.p2}
        link={content.ThirdSec.buttonLink}
        text={content.ThirdSec.buttonText}
        navLinks={content.ThirdSec.navLinks}
      />
      <Form />
      <ContactThirdSec />
      <ContactForthSec />
    </div>
  );
}
