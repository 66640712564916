"use client";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  AiOutlineMail,
  AiOutlineEnvironment,
  AiOutlineUser,
  AiOutlineShoppingCart,
  AiOutlineClose,
  AiOutlineMenu,
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillLinkedin,
  AiOutlineWhatsApp,
} from "react-icons/ai";

import useScreenSize from "../../useScreenSize";

export default function Header() {
  const logo =
    "https://ik.imagekit.io/pcet3dvcu/global/1.png?updatedAt=1726258240115";
  const certification =
    "https://ik.imagekit.io/pcet3dvcu/product/certified_72x.png?updatedAt=1726258061062";

  const { isLargeScreen, isXlScreen } = useScreenSize();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setActiveLink(""); // Reset the active link when closing the menu
  };

  const openLinkScreen = (link) => {
    setActiveLink(link);
  };

  const socialIcons = [
    {
      icon: AiFillFacebook,
      href: "https://www.facebook.com/egy.herbs.global?mibextid=LQQJ4d",
    },
    {
      icon: AiOutlineTwitter,
      href: "https://x.com/egyherbsglobal?s=21&t=PAO3PFihRAQnJvLEBD-sJg",
    },
    {
      icon: AiFillLinkedin,
      href: "https://www.linkedin.com/company/egy-herbs-global/",
    },
    {
      icon: AiFillInstagram,
      href: "https://www.instagram.com/egy.herbsglobal?igsh=MWg2aW5jdWt0b3ZxZA==",
    },
    {
      icon: AiOutlineWhatsApp,
      href: "https://wa.me/201222297357",
    },
  ];

  return (
    <header className="bg-[#fcfbf9] text-[#183028] fixed top-0 left-0 right-0 z-50 shadow-md flex justify-between xl:items-center lg:items-end sm:items-center p-4 lg:pt-9 lg:px-10 xl:py-[30px] xl:px-[100px] transition-all duration-300 ease-in-out">
      {isXlScreen ? (
        <>
          {/* Extra Large Screen Layout */}
          <div className="flex items-center gap-10">
            <Link
              to="/"
              className="icon-Link transition-transform transform hover:scale-105 duration-300"
            >
              <AiOutlineMail className="icon text-[#205c40] text-2xl" />
            </Link>
            <Link
              to="/"
              className="icon-Link transition-transform transform hover:scale-105 duration-300"
            >
              <AiOutlineEnvironment className="icon text-[#205c40] text-2xl" />
            </Link>
            <Link
              to="/products"
              className="hero_title text-[#000] p-2 border-b-2 border-transparent hover:border-[#b0603b] transition-all duration-300"
            >
              PRODUCTS
            </Link>
            <Link
              to="/supplyChain"
              className="hero_title text-[#000] p-2 border-b-2 border-transparent hover:border-[#b0603b] transition-all duration-300"
            >
              PROCESS
            </Link>
          </div>

          <Link
            to="/"
            className="flex justify-center transition-transform transform hover:scale-105 duration-300"
          >
            <img loading="lazy" src={logo} alt="Logo" width={150} />
          </Link>

          <div className="flex items-center gap-10">
            <Link
              to="/about"
              className="hero_title text-[#000] p-2 border-b-2 border-transparent hover:border-[#b0603b] transition-all duration-300"
            >
              ABOUT
            </Link>
            <Link
              to="/contact"
              className="hero_title text-[#000] p-2 border-b-2 border-transparent hover:border-[#b0603b] transition-all duration-300"
            >
              MEET YOUR HERBS
            </Link>
            <Link
              to="/"
              className="icon-Link transition-transform transform hover:scale-105 duration-300"
            >
              <AiOutlineUser className="icon text-[#205c40] text-2xl" />
            </Link>
            <Link
              to="/"
              className="icon-Link transition-transform transform hover:scale-105 duration-300"
            >
              <AiOutlineShoppingCart className="icon text-[#205c40] text-2xl" />
            </Link>
          </div>
        </>
      ) : isLargeScreen ? (
        <>
          {/* 1024px to 1279px */}
          <div className="flex gap-14">
            <Link
              to="/"
              className="flex justify-center mb-2 transition-transform transform hover:scale-105 duration-300"
            >
              <img loading="lazy" src={logo} alt="Logo" width={150} />
            </Link>

            <div className="flex items-end gap-10">
              <Link
                to="/products"
                className="hero_title text-[#000] p-2 border-b-2 border-transparent hover:border-[#b0603b] transition-all duration-300"
                style={{
                  fontSize: "13px",
                  fontFamily: "MarkPro",
                  fontWeight: "500",
                }}
              >
                PRODUCTS
              </Link>
              <Link
                to="/about"
                className="hero_title text-[#000] p-2 border-b-2 border-transparent hover:border-[#b0603b] transition-all duration-300"
                style={{
                  fontSize: "13px",
                  fontFamily: "MarkPro",
                  fontWeight: "500",
                }}
              >
                ABOUT
              </Link>
              <Link
                to="/supplyChain"
                className="hero_title text-[#000] p-2 border-b-2 border-transparent hover:border-[#b0603b] transition-all duration-300"
                style={{
                  fontSize: "13px",
                  fontFamily: "MarkPro",
                  fontWeight: "500",
                }}
              >
                PROCESS
              </Link>
              <Link
                to="/contact"
                className="hero_title text-[#000] p-2 border-b-2 border-transparent hover:border-[#b0603b] transition-all duration-300"
                style={{
                  fontSize: "13px",
                  fontFamily: "MarkPro",
                  fontWeight: "500",
                }}
              >
                MEET YOUR HERBS
              </Link>
            </div>
          </div>

          <div className="flex gap-7 mb-2">
            <Link
              to="/"
              className="icon-Link transition-transform transform hover:scale-105 duration-300"
            >
              <AiOutlineMail className="icon text-[#205c40] text-2xl" />
            </Link>
            <Link
              to="/"
              className="icon-Link transition-transform transform hover:scale-105 duration-300"
            >
              <AiOutlineEnvironment className="icon text-[#205c40] text-2xl" />
            </Link>
            <Link
              to="/"
              className="icon-Link transition-transform transform hover:scale-105 duration-300"
            >
              <AiOutlineUser className="icon text-[#205c40] text-2xl" />
            </Link>
            <Link
              to="/"
              className="icon-Link transition-transform transform hover:scale-105 duration-300"
            >
              <AiOutlineShoppingCart className="icon text-[#205c40] text-2xl" />
            </Link>
          </div>
        </>
      ) : (
        <>
          {/* Small Screen Layout */}
          <div className="flex justify-between items-center w-full fixed top-0 left-0 right-0 z-50 bg-[#fcfbf9] px-5 py-4 shadow-sm">
            <Link
              to="/"
              className="flex items-center transition-transform transform hover:scale-105 duration-300"
              onClick={() => setIsMenuOpen(false)} // Close menu on logo click
            >
              <img loading="lazy" src={logo} alt="Logo" width={130} />
            </Link>
            <div className="flex items-center gap-3">
              <Link
                to="/"
                className="icon-Link transition-transform transform hover:scale-105 duration-300"
                onClick={() => setIsMenuOpen(false)} // Close menu on link click
              >
                <AiOutlineMail className="icon text-[#205c40] text-2xl" />
              </Link>
              <Link
                to="/"
                className="icon-Link transition-transform transform hover:scale-105 duration-300"
                onClick={() => setIsMenuOpen(false)} // Close menu on link click
              >
                <AiOutlineEnvironment className="icon text-[#205c40] text-2xl" />
              </Link>
              <Link
                to="/"
                className="icon-Link transition-transform transform hover:scale-105 duration-300"
                onClick={() => setIsMenuOpen(false)} // Close menu on link click
              >
                <AiOutlineShoppingCart className="icon text-[#205c40] text-2xl" />
              </Link>
              <Link
                to="/"
                className="icon-Link transition-transform transform hover:scale-105 duration-300"
                onClick={() => setIsMenuOpen(false)} // Close menu on link click
              >
                <AiOutlineUser className="icon text-[#205c40] text-2xl" />
              </Link>
              <button onClick={toggleMenu} className="text-xl text-[#205c40]">
                {isMenuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
              </button>
            </div>
          </div>

          {/* Fullscreen Menu */}
          <div
            className={`fixed inset-0 bg-[#fcfbf9] text-black z-40 flex flex-col items-center gap-16 mt-[80px] transition-all duration-300 ease-in-out transform ${
              isMenuOpen
                ? "translate-x-0 opacity-100"
                : "translate-x-full opacity-0"
            }`}
          >
            <div className="flex flex-col justify-center w-full">
              <div className="flex justify-between items-center p-6 shadow-sm">
                <Link
                  to="/products"
                  onClick={() => setIsMenuOpen(false)} // Close menu on link click
                  className="hero_title text-[#000] flex-1"
                >
                  PRODUCTS
                </Link>
              </div>
              <div className="flex justify-between items-center p-6 shadow-sm">
                <Link
                  to="/supplyChain"
                  onClick={() => setIsMenuOpen(false)} // Close menu on link click
                  className="hero_title text-[#000] flex-1"
                >
                  PROCESS
                </Link>
              </div>
              <div className="flex justify-between items-center p-6 shadow-sm">
                <Link
                  to="/about"
                  onClick={() => setIsMenuOpen(false)} // Close menu on link click
                  className="hero_title text-[#000] flex-1"
                >
                  ABOUT
                </Link>
              </div>
              <div className="flex justify-between items-center p-6 shadow-sm">
                <Link
                  to="/contact"
                  onClick={() => setIsMenuOpen(false)} // Close menu on link click
                  className="hero_title text-[#000] flex-1"
                >
                  MEET YOUR HERBS
                </Link>
              </div>
            </div>
            {/* Social Section */}
            <div className="flex flex-col justify-between items-center gap-5">
              {/* Certification Section */}
              <div className="flex justify-evenly w-3/4 items-center p-4 bg-[#204c3a] rounded-lg shadow-lg">
                <p className="hero_title text-white w-2/3">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                </p>
                <div className="border-l-2 border-white pl-5">
                  <img
                    loading="lazy"
                    src={certification}
                    alt="Certified"
                    width={50}
                    height={50}
                  />
                </div>
              </div>

              {/* Social Icons Section */}
              <div className="flex justify-center gap-4">
                {socialIcons.map((social, index) => (
                  <Link
                    to={social.href}
                    target="_blank"
                    className="social-icon"
                    key={index}
                    onClick={() => setIsMenuOpen(false)} // Close menu on social icon click
                  >
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-white text-[#183028]">
                      <social.icon size={25} />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </header>
  );
}
