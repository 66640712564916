import React from "react";
import { BiSolidStar } from "react-icons/bi";
import { BiMinus } from "react-icons/bi";

export default function SixthSec({
  backGroundImg,
  h1,
  p1,
  p2,
  p3,
  span,
  button,
}) {
  const StarRating = ({ rating }) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<BiSolidStar className="text-[#9ba839]" key={i} />);
    }
    return <span className="flex">{stars}</span>;
  };

  return (
    <div className="py-12 px-6 flex justify-center items-center h-fit bg-[#596221]">
      <div className="container flex flex-col justify-center items-center lg:flex-row h-[80vh] p-0 gap-5 lg:px-10">
        {/* Rating and Title */}
        <div className="flex items-center gap-2 lg:hidden">
          <span className="text-lg text-white">4.8</span>
          <StarRating rating={5} />
        </div>
        {/* Right Side - Content */}
        <div className="flex text-white flex-col justify-evenly items-center lg:items-start w-full lg:w-1/2 gap-3 lg:gap-10 p-0 lg:px-10">
          {/* Rating and Title */}
          <div className="items-center gap-2 hidden lg:flex">
            <span className="text-lg text-white">4.8</span>
            <StarRating rating={5} />
          </div>
          <h1 className="font-tfn text-4xl text-white">{h1}</h1>

          {/* Text Sections with Toggle */}
          <div className="w-full flex justify-between items-center text-white">
            <p className="">{p1}</p>
            <BiMinus />
          </div>
          <p className="paragraph text-start lg:text-start text-white">{p2}</p>

          <div className="w-full flex justify-between items-center">
            <p className="">{p3}</p>
            <BiMinus />
          </div>

          {/* Call-to-action Button */}
          <button className="btn">{button}</button>
        </div>
        {/* Left Side - Background Image */}
        <div
          className="relative h-1/2 lg:h-full w-full lg:w-1/2"
          style={{
            background: `url(${backGroundImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
      </div>
    </div>
  );
}
