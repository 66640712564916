import React from "react";
import { AboutData } from "../../data";
import "./About.css";
import SecondSec from "./Second-Sec/Second-Sec";
import ForthSec from "./Forth-Sec/Forth-Sec";
import ThirdSec from "./Third-Sec/Third-Sec";
import NinthSec from "../NinthSec";

export default function About() {
  const content = AboutData[0];

  return (
    <div className="About">
      <NinthSec
        backgroundImage={content.Hero.backGroundImage}
        spans={content.Hero.spans}
        p={content.Hero.p}
      />
      <SecondSec />
      <ForthSec />
      <div className="flex justify-center px-10">
        <ThirdSec />
      </div>
    </div>
  );
}
