// NinthSec.js
import React from "react";
import { Link } from "react-router-dom";

export default function NinthSec({ backgroundImage, spans, p, button, link }) {
  return (
    <div
      className="w-full h-[80vh] lg:h-[100vh] bg-ninth-bg bg-cover bg-center text-white flex items-center justify-center"
      style={{
        background: `url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="container w-full flex items-center justify-center px-2 lg:px-5">
        <div className="flex flex-col w-full lg:w-2/4 h-full justify-between items-center text-center bg-[#0009] rounded-lg font-MarkPro font-bold py-8 px-5 lg:px-10 gap-10">
          <span className="text-sm">{spans.span1}</span>
          <span className="text-5xl font-tfn">{spans.span2}</span>
          <p className="font-Afterglow font-thin text-lg">{p}</p>
          {button && (
            <Link className="btn" to={link}>
              {button}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
