import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import GlobalStyle from "./GlobalStyles";
import "./globals.css";
import Header from "./Components/Header/nav";
import TenthSec from "./Components/Home-Page/10-Tenth-Sec/Tenth-Sec";
import HomePage from "./Components/Home-Page/Home-Page";
import ProductPage from "./Components/Product-Page/Product-Page";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import FloatingActionButton from "./Components/FloatingActionButton/Test";
import SupplyChain from "./Components/Supply-Chain/Supply-Chain";
import FooterTest from "./Components/Home-Page/11-Footer/FooterTest";
import { preloadImages } from "./utils/preloadImages";

// Array of all images to preload
const allImages = [
  // Home page images
  "https://ik.imagekit.io/pcet3dvcu/home/1.png?updatedAt=1726258403481",
  // Add all other image URLs here...
];

export default function App() {
  const logo =
    "https://ik.imagekit.io/pcet3dvcu/global/1.png?updatedAt=1726258240115";

  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Preload all images
    preloadImages(allImages).then(() => {
      setLoading(false); // Set loading to false once all images are loaded
    });

    // Scroll to the top on route change
    window.scrollTo(0, 0);
  }, [location]);

  // if (loading) {
  //   return (
  //     <div className="loading-screen">
  //       <img src={logo} alt="Loading..." />
  //       {/* You can add a loading spinner or any other effect here */}
  //     </div>
  //   );
  // }

  return (
    <>
      <GlobalStyle />
      <div className="App">
        <Header />
        <div>
          <Routes location={location}>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<ProductPage />} />
            <Route path="/supplychain" element={<SupplyChain />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <FloatingActionButton />
        <TenthSec />
        {/* <FooterTest /> */}
      </div>
    </>
  );
}
