import React from "react";
import { HomePageData } from "../../../data";

export default function TenthSec() {
  const tenthSecContent = HomePageData.map((item) => item.TenthSec)[0];

  return (
    <div className="flex items-center justify-center bg-[#fcfbf9] py-5">
      <div className="container w-full h-full flex flex-wrap justify-center items-center gap-6">
        {tenthSecContent.map((card, cardIndex) => (
          <div
            className="w-[150px] lg:w-[300px] p-5 flex flex-col gap-5 justify-between items-center rounded-lg"
            key={cardIndex}
          >
            <div className="card-icon flex justify-center items-center">
              <img loading="lazy" src={card.cardImage} alt="icon" />
            </div>
            <span className="card-title text-center text-[16px] font-tfn font-thin text-[#16402d]">
              {card.tittle}
            </span>
            <span className="card-paragraph text-center text-[12px] font-MarkPro font-semibold text-[#000]">
              {card.p}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
