import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function SupplyThirdSec({
  backGroundImg,
  p1,
  h1,
  p2,
  link,
  text,
  navLinks,
  direction,
  btn,
}) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      controls.start("show");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <div className="overflow-hidden flex justify-center items-center h-fit px-5 lg:px-20 w-full over">
      <div
        className="flex flex-col lg:flex-row w-full"
        style={{ direction: direction }}
        ref={ref}
      >
        <motion.div
          style={{
            background: `url(${backGroundImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="relative w-full lg:w-2/4 h-[400px] lg:h-auto"
          variants={{
            hidden: { opacity: 0 },
            show: { opacity: 1 },
          }}
          initial="hidden"
          animate={controls}
          transition={{
            duration: 1,
            ease: "easeOut",
          }}
        ></motion.div>

        <div
          className="w-full bg-[#eaefec] lg:w-2/4 flex flex-col justify-evenly lg:justify-center items-start px-5 py-20 lg:px-12 lg:py-28 gap-8"
          style={{ direction: "initial" }}
        >
          {p1 && (
            <motion.p
              className="paragraph text-sm"
              variants={{
                hidden: { opacity: 0, x: 100 },
                show: { opacity: 1, x: 0 },
              }}
              initial="hidden"
              animate={controls}
              transition={{
                duration: 0.5,
                ease: "easeOut",
                delay: 0.4,
              }}
            >
              {p1}
            </motion.p>
          )}
          <motion.h1
            className="text-4xl font-tf text-[#16402d]"
            variants={{
              hidden: { opacity: 0, x: 200 },
              show: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={controls}
            transition={{
              duration: 0.5,
              ease: "easeOut",
            }}
          >
            {h1}
          </motion.h1>
          {p2 && (
            <motion.p
              className="paragraph text-md text-[#524f4f]"
              variants={{
                hidden: { opacity: 0, x: 200 },
                show: { opacity: 1, x: 0 },
              }}
              initial="hidden"
              animate={controls}
              transition={{
                duration: 0.5,
                ease: "easeOut",
                delay: 0.4,
              }}
            >
              {p2}
            </motion.p>
          )}
          {text && (
            <Link to={link} className="about-btn">
              {text}
            </Link>
          )}
          {navLinks && (
            <ul>
              {navLinks.map(({ text, link }, index) => (
                <li key={index}>
                  <Link to={link} className="contact-third-link">
                    {text}
                  </Link>
                </li>
              ))}
            </ul>
          )}
          {btn && (
            <Link to="/" className="about-btn">
              {btn}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
