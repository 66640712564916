import React from "react";
import { Link } from "react-router-dom";

export default function NinthSec({ backgroundImage, spans, p, button, link }) {
  return (
    <div
      className="w-full h-[45vh] pt-10 lg:h-[70vh] text-white flex items-center justify-center overflow-hidden"
      style={{
        background: `url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      {/* Content */}
      <div className="relative flex w-full h-full justify-center items-center bg-[#0009] font-MarkPro font-bold pt-10 lg:pt-20">
        <div className="flex flex-col h-full justify-center items-center text-center gap-5 lg:gap-10 w-full lg:w-1/2 p-5 lg:px-20">
          <span className="text-sm">{spans.span1}</span>
          <span className="text-5xl font-tf">{spans.span2}</span>
          <p className="font-Afterglow font-thin text-lg">{p}</p>
          {button && (
            <Link to={link} className="btn">
              {button}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
