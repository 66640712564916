import React from "react";
import "./Fifth-Sec.css";

export default function FifthSec({
  backGroundImg,
  Stiker,
  h1,
  p1,
  p2,
  p3,
  button,
}) {
  return (
    <div className="py-12 bg-[#eae1d2] px-6 flex justify-center items-center h-fit">
      <div className="container flex flex-col justify-between items-center lg:flex-row h-[80vh] p-0 gap-5 lg:gap-5 lg:px-10">
        <div
          className="relative h-2/4 lg:h-full w-full lg:w-1/2"
          style={{
            background: `url(${backGroundImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="bg-stiker-bg bg-center bg-cover lg:h-full flex flex-col justify-evenly items-center lg:items-start w-full lg:w-2/4 px-0 lg:px-10 gap-3 lg:gap-0">
          <p className="top text-center lg:text-start">{p1}</p>
          <h1 className="font-tfn text-4xl text-center lg:text-start">{h1}</h1>
          <p className="paragraph text-center lg:text-start">{p2}</p>
          <p className="paragraph text-center lg:text-start">{p3}</p>
          <button className="fifthBtn">{button}</button>
        </div>
      </div>
    </div>
  );
}
