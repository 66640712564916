import React from "react";
import { Link } from "react-router-dom";
import "swiper/swiper-bundle.css";
import "./Hero.css";
// import { HomePageData } from "../Home-Page-Data/Home-Page-Data";
import { HomePageData } from "../../../data";

export default function Hero() {
  const heroContent = HomePageData.map((item) => item.Hero)[0];

  return (
    <div
      className="hero bg-hero-bg bg-cover bg-center lg:bg-center flex items-center justify-center lg:justify-start"
      style={{
        background: `url(${heroContent.backGroundImg})`,
        backgroundSize: "cover",
      }}
      loading="lazy"
    >
      <div className="flex items-center">
        <div className="m-0 mb-52 lg:mb-0 lg:mt-10 lg:ml-24">
          <div className="heading gap-2 flex flex-col items-center lg:items-start text-center lg:text-left">
            <h1 className="font-tf text-white" style={{ fontSize: "60px" }}>
              {heroContent.heading.title}
            </h1>
            <p className="heading-2">{heroContent.heading.slogan}</p>
            <Link to={heroContent.button.link} className="btn mt-5">
              {heroContent.button.text}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
