import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function NewsLetterCard({
  cardImage,
  span,
  span2,
  h2,
  p,
  signature,
  cardVideoUrl,
  cardVideoTittle,
}) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      controls.start("show");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      className="flex flex-col justify-between p-1.5 swiper-slide max-w-sm md:max-w-xs"
      variants={{
        hidden: { opacity: 0 },
        show: { opacity: 1 },
      }}
      transition={{
        duration: 0.6,
        ease: "easeInOut",
      }}
    >
      {cardImage && (
        <div
          className="h-1/2 bg-center bg-cover"
          style={{ background: `url(${cardImage})`, backgroundSize:"cover", backgroundPosition:"center" }}
        ></div>
      )}
      {cardImage && (
        <div className="flex flex-col justify-evenly h-1/2 p-2 text-[#16402d]">
          <div className="flex justify-between items-center">
            <span className="text-white bg-[#16402d] p-1.5 rounded-sm text-xs">
              {span}
            </span>
            <span className="text-white bg-[#16402d] p-1.5 rounded-sm text-xs">
              {span2}
            </span>
          </div>
          <h2 className="text-lg">{h2}</h2>
          <p className="text-xs">{p}</p>
          <span className="text-lg">{signature}</span>
        </div>
      )}
      {cardVideoUrl && (
        <div className="flex-1 bg-center bg-cover rounded-lg overflow-hidden">
          <iframe
            src={cardVideoUrl}
            allowFullScreen
            className="w-full h-full rounded-lg border-none"
          ></iframe>
        </div>
      )}
    </motion.div>
  );
}
