import React from "react";
import "./Seventh-Sec.css";
import { Link } from "react-router-dom";
import { HomePageData } from "../../../data";

export default function SeventhSec() {
  const seventhSecContent = HomePageData.map((item) => item.SeventhSec)[0];

  return (
    <div className="seventh-sec flex items-center justify-center">
      <div className="container flex flex-col items-center justify-between gap-10 pt-0 pb-20 px-10">
        <div className="upper-div text-center">
          <span className="text-4xl font-tf">{seventhSecContent.title}</span>
        </div>
        <div className="lower-div flex flex-col lg:flex-row gap-5">
          {seventhSecContent.Cards.map((card, cardIndex) => (
            <div
              className="card-div flex flex-col items-center justify-between gap-4 p-6 w-full max-w-xs lg:max-w-sm"
              key={cardIndex}
            >
              <div className="card-top flex flex-col items-center justify-center gap-4 flex-grow">
                <img
                  loading="lazy"
                  src={card.cardImage}
                  alt="image"
                  width={100}
                  className="object-cover"
                />
                <div className="text-xl">{card.tittle}</div>
                <div className="text-center text-[#7d7d7d]">{card.p}</div>
              </div>
              <Link
                to="/"
                className="link" // Preserve existing color or adjust as needed
              >
                {card.button}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
