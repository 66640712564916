import React from "react";
import "./Second-Sec.css";
import { AboutData } from "../../../data";
import {
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoTwitter,
  BiLogoLinkedin,
} from "react-icons/bi";
import { Link } from "react-router-dom";

export default function SecondSec() {
  const secondSecContent = AboutData.map((item) => item.SecondSec)[0];

  return (
    <div className="Second-Sec">
      <div className="container w-[85%] lg:w-[80%]">
        <div className="about-upper-text gap-5">
          <div className="about-icons">
            <Link
              to="https://www.facebook.com/egy.herbs.global?mibextid=LQQJ4d"
              className="about-icon"
              target="blank"
            >
              <BiLogoFacebook />
            </Link>
            <Link
              to="https://www.instagram.com/egy.herbsglobal?igsh=MWg2aW5jdWt0b3ZxZA=="
              className="about-icon"
              target="blank"
            >
              <BiLogoInstagram />
            </Link>
            <Link
              to="https://x.com/egyherbsglobal?s=21&t=PAO3PFihRAQnJvLEBD-sJg"
              className="about-icon"
              target="blank"
            >
              <BiLogoTwitter />
            </Link>
            <Link
              to="https://www.linkedin.com/company/egy-herbs-global/"
              className="about-icon"
              target="blank"
            >
              <BiLogoLinkedin />
            </Link>
          </div>
          <h1 className="h1 font-tfn">Doing Good, Works.</h1>
          <p>
            At Egy Herbs Global, we specialize in the export of high-quality
            dried herbs and plants, providing reliable B2B solutions to
            companies worldwide. With over 30 years of experience in the local
            market, we are now bringing our expertise to international partners,
            ensuring the best products for your business needs .
          </p>
        </div>
      </div>
    </div>
  );
}
