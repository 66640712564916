"use client";
import React, { useEffect, useRef, useState } from "react";
import "../../globals.css";
import { HomePageData } from "../../data";
import Hero from "./1-Hero/Hero";
import BestSellers from "./2-Best-Sellers/Best-Sellers";
import ThirdSec from "./3-Third-Sec/Third-Sec";
import ForthSec from "./4-Forth-Sec/Forth-Sec";
import FifthSec from "./5-Fifth-Sec/Fifth-Sec";
import SixthSec from "./6-Sixth-Sec/Sixth-Sec";
import SeventhSec from "./7-Seventh-sec/Seventh-sec";
import EighthsSec from "./8-Eighths-sec/Eighths-sec";
import NinthSec from "./9-Ninth-Sec/Ninth-Sec";

export default function HomePage() {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && isVideoPlaying) {
      videoRef.current.play();
    }
  }, [isVideoPlaying]);

  const content = HomePageData[0];

  return (
    <div className="Home">
      <Hero />
      <BestSellers />
      <ThirdSec
        backGroundImg={content.ThirdSec.backGroundImg}
        p1={content.ThirdSec.p1}
        h1={content.ThirdSec.h1}
        p2={content.ThirdSec.p2}
        link={content.ThirdSec.buttonLink}
        text={content.ThirdSec.buttonText}
      />
      <ForthSec />
      <FifthSec
        backGroundImg={content.FifhSec.backGroundImg}
        Stiker={content.FifhSec.backGroundStiker}
        h1={content.FifhSec.text.h1}
        p1={content.FifhSec.text.paragraph.p1}
        p2={content.FifhSec.text.paragraph.p2}
        p3={content.FifhSec.text.paragraph.p3}
        button={content.FifhSec.button}
      />
      <SixthSec
        backGroundImg={content.SixthSec.backGroundImg}
        h1={content.SixthSec.text.h1}
        p1={content.SixthSec.text.paragraph.p1}
        p2={content.SixthSec.text.paragraph.p2}
        p3={content.SixthSec.text.paragraph.p3}
        span={content.SixthSec.text.paragraph.p3}
        button={content.SixthSec.button}
      />
      <SeventhSec />
      <EighthsSec />
      <NinthSec
        backgroundImage={content.NinthSec.backgroundImage}
        spans={content.NinthSec.spans}
        p={content.NinthSec.p}
        button={content.NinthSec.button}
        link={content.NinthSec.link}
      />
    </div>
  );
}
