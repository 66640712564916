import React from "react";
import { Link } from "react-router-dom";

export default function ContactForthSec() {
  const bg =
    "https://ik.imagekit.io/pcet3dvcu/contact/2.png?updatedAt=1726259053045";
  return (
    <div
      className="w-full bg-cover bg-center text-white flex items-center justify-center lg:justify-start py-10 px-5 lg:py-24 lg:px-20"
      style={{
        background: `url(${bg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="flex flex-col w-full lg:w-1/3 h-full justify-between text-start bg-[#0009] rounded-lg font-MarkPro font-bold py-14 pr-10 pl-5 gap-10">
        <span className="text-5xl font-tfn">
          Egyptain Plants, Global Impact
        </span>
        <p className="font-Afterglow font-thin text-lg">
          Discover Our Collection of Premium Herbal Products
        </p>
        <Link to="/" className="btn">
          LEARN MORE
        </Link>
      </div>
    </div>
  );
}
