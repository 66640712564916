import React from "react";
import { ProductsData } from "../../data";
import "./Product-Page.css";
import NinthSec from "../NinthSec";
import ThirdSec from "./Third-Sec/Third-Sec";
import SecondSec from "./Second-Sec/Second-Sec";

export default function ProductPage() {
  const content = ProductsData[0];

  return (
    <div className="Products gap-24">
      <NinthSec
        backgroundImage={content.Hero.backgroundImage}
        spans={content.Hero.spans}
        p={content.Hero.p}
      />
      <SecondSec
        Type={content.SecondSec.Type}
        ProductCardData={content.SecondSec.ProductCardData}
      />
      <div className="flex justify-center px-5">
        <ThirdSec />
      </div>
    </div>
  );
}
