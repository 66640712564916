import { useEffect, useRef } from "react";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import StarRating from "../StarRating/StarRating";
import { HomePageData } from "../../../data";
import NewsLetterCard from "./NewsLetterCard/NewsLetterCard";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function ForthSec() {
  const fothSecContent = HomePageData.map((item) => item.ForthSec.title)[0];
  const fothSecContentCard = HomePageData.map(
    (item) => item.ForthSec.newsLetterCards
  )[0];

  const swiperRef = useRef(null);

  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    swiperRef.current = new Swiper(".swiper-container", {
      spaceBetween: 5,
      centeredSlides: true,
    });
  }, []);

  useEffect(() => {
    if (inView) {
      controls.start("show");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <div className="h-screen">
      <div className="flex flex-col md:flex-row p-0 lg:px-20 justify-between items-center h-full overflow-hidden">
        <div className="flex flex-col justify-between items-center md:items-start gap-8 w-fit">
          <StarRating rating={5} />
          <p className="text-4xl text-center md:text-start font-tf">
            {fothSecContent}
          </p>
        </div>
        <motion.div
          className="swiper-container sellers h-fit"
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={{
            hidden: { opacity: 0 },
            show: {
              opacity: 1,
              transition: {
                staggerChildren: 0.25,
              },
            },
          }}
        >
          <div
            className="swiper-wrapper ss flex gap-5
          "
          >
            {fothSecContentCard.map((card) => (
              <NewsLetterCard key={card.id} {...card} />
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
}
