import React from "react";
import { Link } from "react-router-dom";

export default function ThirdSec({
  backGroundImg,
  p1,
  h1,
  p2,
  p3,
  p4,
  p5,
  p6,
  direction,
  btn,
}) {
  return (
    <div className="flex justify-center items-center w-[85%] lg:w-[80%] h-fit">
      <div
        className="flex flex-col lg:flex-row bg-[#eaefec] w-full"
        style={{ direction: direction }}
      >
        <div
          className="left-div w-full lg:w-2/4 h-[400px] lg:h-auto"
          style={{
            background: `url(${backGroundImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
        <div
          className="w-full lg:w-2/4 flex flex-col justify-evenly items-start px-5 py-10 lg:px-12 lg:py-20 gap-8"
          style={{ direction: "initial" }}
        >
          <h1 className="text-5xl font-tf">{h1}</h1>
          <p className="paragraph">{p1}</p>
          {p2 && <p className="paragraph">{p2}</p>}
          {p3 && <p className="paragraph">{p3}</p>}
          {p4 && <p className="paragraph">{p4}</p>}
          {p5 && <p className="paragraph">{p5}</p>}
          {p6 && <p className="paragraph">{p6}</p>}
          {btn && (
            <Link to="/" className="about-btn">
              {btn}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
