import React from "react";
import { ProductsData } from "../../../data";

export default function ThirdSec() {
  const certification =
    "https://ik.imagekit.io/pcet3dvcu/product/certified_72x.png?updatedAt=1726258061062";
  const ThirdSecContent = ProductsData.map((item) => item.ThirdSec)[0];

  return (
    <div className="flex items-center w-full lg:w-2/4 p-4 bg-[#204c3a] rounded-lg shadow-lg">
      <p className="w-2/3 text-white">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
      </p>
      <div className="border-l-2 border-white pl-5">
        <img loading="lazy" src={certification} alt="Certified" width={60} />
      </div>
    </div>
  );
}
