import React from "react";
import { Link } from "react-router-dom";
import { HomePageData } from "../../../data";

export default function EighthsSec() {
  const eighthSecContent = HomePageData[0].EighththSec;

  return (
    <div className="flex items-center justify-center pb-10">
      <div className="container h-full flex flex-col items-center justify-between gap-5">
        <div className="text-4xl font-thin text-[#8daf56] flex flex-col items-center justify-between gap-3">
          <span className="text-sm font-bold text-[#73857f]">
            {eighthSecContent.title.span1}
          </span>
          <span>{eighthSecContent.title.span2}</span>
        </div>
        <div className="flex flex-col lg:flex-row justify-evenly items-center gap-10 lg:gap-5 p-8">
          {eighthSecContent.Cards.map((card) => (
            <div
              key={card.id}
              className="h-full w-full flex flex-col justify-between items-center gap-8 cursor-pointer"
            >
              <div
                className="relative h-[300px] w-full transition-transform duration-300 overflow-hidden"
                style={{
                  background: `url(${card.cardImage})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="flex flex-col justify-between gap-5 px-4">
                <div className="flex gap-2">
                  <span className="text-xs font-bold bg-[#eaefec] text-black p-2 rounded-full">
                    {card.spans.span1}
                  </span>
                  <span className="text-xs font-bold bg-[#eaefec] text-black p-2 rounded-full">
                    {card.spans.span2}
                  </span>
                </div>
                <span className="text-2xl font-tfn">{card.tittle}</span>
                <p className="text-sm text-black leading-6">{card.p}</p>
                <Link to="/" className="text-[#16402d]">
                  {card.button}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
