// utils/preloadImages.js
export const preloadImages = (imageUrls) => {
  return Promise.all(
    imageUrls.map((src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve();
      });
    })
  );
};
