// data from python
import extractedData from "./request_images/extracted_data.json";

// Home page images
const heroSecImg =
  "https://ik.imagekit.io/pcet3dvcu/home/1.png?updatedAt=1726258403481";
const thidSecImg =
  "https://ik.imagekit.io/pcet3dvcu/home/2.jpg?updatedAt=1726258403481";
const newsLetterCardImage1 =
  "https://ik.imagekit.io/pcet3dvcu/home/5.jpg?updatedAt=1726258403481";
const fifthSecImg =
  "https://ik.imagekit.io/pcet3dvcu/home/3.jpg?updatedAt=1726258403481";
const fifthSecStiker =
  "https://ik.imagekit.io/pcet3dvcu/home/1.png?updatedAt=1726258403481";
const sixthSecImg =
  "https://ik.imagekit.io/pcet3dvcu/home/66.jpg?updatedAt=1726258403481";
const seventhcardIcon1 =
  "https://ik.imagekit.io/pcet3dvcu/home/7.png?updatedAt=1726258403481";
const seventhcardIcon2 =
  "https://ik.imagekit.io/pcet3dvcu/home/8.png?updatedAt=1726258403481";
const seventhcardIcon3 =
  "https://ik.imagekit.io/pcet3dvcu/home/9.png?updatedAt=1726258403481";
const seventhcardIcon4 =
  "https://ik.imagekit.io/pcet3dvcu/home/10.png?updatedAt=1726258403481";
const EighthSecImg1 =
  "https://ik.imagekit.io/pcet3dvcu/home/77.jpg?updatedAt=1726258403481";
const EighthSecImg2 =
  "https://ik.imagekit.io/pcet3dvcu/home/77.jpg?updatedAt=1726258403481";
const NinthSecImg =
  "https://ik.imagekit.io/pcet3dvcu/home/12.jpg?updatedAt=1726258403481";
const tenththcardIcon1 =
  "https://ik.imagekit.io/pcet3dvcu/global/3.png?updatedAt=1726258301227";
const tenththcardIcon2 =
  "https://ik.imagekit.io/pcet3dvcu/global/4.png?updatedAt=1726258301227";
const tenththcardIcon3 =
  "https://ik.imagekit.io/pcet3dvcu/global/5.png?updatedAt=1726258301227";
const tenththcardIcon4 =
  "https://ik.imagekit.io/pcet3dvcu/global/6.png?updatedAt=1726258301227";

// Products page images
const productHeroSecImg =
  "https://ik.imagekit.io/pcet3dvcu/product/6.jpg?updatedAt=1726262461661";
const productThirdSecImg =
  "https://ik.imagekit.io/pcet3dvcu/product/certified_72x.png?updatedAt=1726258061062";
const productForthSecImg =
  "https://ik.imagekit.io/pcet3dvcu/product/certified_72x.png?updatedAt=1726258061062";

// About page images
const aboutHeroSecImg =
  "https://ik.imagekit.io/pcet3dvcu/testing/s1.jpg?updatedAt=1726258043935";
const about1 =
  "https://ik.imagekit.io/pcet3dvcu/about/1.jpg?updatedAt=1726262461661";
const about2 =
  "https://ik.imagekit.io/pcet3dvcu/about/2.jpg?updatedAt=1726262461661";
const about3 =
  "https://ik.imagekit.io/pcet3dvcu/about/3.jpg?updatedAt=1726262461661";
const about4 =
  "https://ik.imagekit.io/pcet3dvcu/about/4.jpg?updatedAt=1726262461661";
const about5 =
  "https://ik.imagekit.io/pcet3dvcu/about/5.jpg?updatedAt=1726262461661";
const about6 =
  "https://ik.imagekit.io/pcet3dvcu/about/6.jpg?updatedAt=1726262461661";
const about7 =
  "https://ik.imagekit.io/pcet3dvcu/about/7.jpg?updatedAt=1726262461661";
const about8 =
  "https://ik.imagekit.io/pcet3dvcu/about/8.jpg?updatedAt=1726262461661";
const about9 =
  "https://ik.imagekit.io/pcet3dvcu/about/9.jpg?updatedAt=1726262461661";
const about10 =
  "https://ik.imagekit.io/pcet3dvcu/about/10.jpg?updatedAt=1726262461661";

// Supply page images
const supplyChainForthSecImg =
  "https://ik.imagekit.io/pcet3dvcu/supplyChain/1.jpg?updn+++++++atedAt=1726259187693";
const farmingImage =
  "https://ik.imagekit.io/pcet3dvcu/supplyChain/1.jpg?updatedAt=1726259187693";
const dryingImage =
  "https://ik.imagekit.io/pcet3dvcu/supplyChain/2.jpeg?updatedAt=1726259187693";
const procesingImage =
  "https://ik.imagekit.io/pcet3dvcu/supplyChain/3.jpeg?updatedAt=1726259187693";
const packagingImage =
  "https://ik.imagekit.io/pcet3dvcu/supplyChain/4.jpeg?updatedAt=1726259187693";
const deliverygImage =
  "https://ik.imagekit.io/pcet3dvcu/supplyChain/5.jpeg?updatedAt=1726259187693";
const customergImage =
  "https://ik.imagekit.io/pcet3dvcu/supplyChain/6.jpeg?updatedAt=1726259187693";

// Contact page images
const contactHeroSecImg =
  "https://ik.imagekit.io/pcet3dvcu/contact/s5.jpg?updatedAt=1726259018820";
const contactSecondSecImg =
  "https://ik.imagekit.io/pcet3dvcu/contact/1.jpg?updatedAt=1726259018820";
const contactThirdSecImg =
  "https://ik.imagekit.io/pcet3dvcu/contact/1.jpg?updatedAt=1726259018820";
const contactForthSecImg =
  "https://ik.imagekit.io/pcet3dvcu/contact/1.jpg?updatedAt=1726259018820";
const CommingSoonProducts =
  "https://ik.imagekit.io/pcet3dvcu/testing/commingSoon.jpg?updatedAt=1726259018820";

export const HomePageData = [
  {
    Hero: {
      backGroundImg: heroSecImg,
      heading: {
        title: "Egy Herbs Global",
        slogan: "Your Passport to Herbal Purity Treasures From Soil to Soul.",
      },
      button: {
        text: "DESCOVER",
        link: "/Products",
      },
    },

    BestSellers: {
      title: "Shop Best Sellers",
      cards: [
        // Herbs
        {
          id: 1,
          y: 100,
          delay: 0.2,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Herbs/1.PNG?updatedAt=1726243616623`,
          cardText: {
            span: "Foundation Herbs",
            h2: `Herb Product 1`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/herbs/1`,
        },
        {
          id: 2,
          y: -100,
          delay: 0.4,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Herbs/2.PNG?updatedAt=1726243616623`,
          cardText: {
            span: "Foundation Herbs",
            h2: `Herb Product 2`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/herbs/2`,
        },
        // ... and so on for the rest of the best sellers in each category
        {
          id: 3,
          y: 100,
          delay: 0.6,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Herbs/3.PNG?updatedAt=1726243616623`,
          cardText: {
            span: "Foundation Herbs",
            h2: `Herb Product 3`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/herbs/3`,
        },
        // ...
        {
          id: 8,
          y: -100,
          delay: 1.6,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Herbs/8.PNG?updatedAt=1726243616623`,
          cardText: {
            span: "Foundation Herbs",
            h2: `Herb Product 8`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/herbs/8`,
        },
        {
          id: 12,
          y: 100,
          delay: 2.4,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Herbs/12.PNG?updatedAt=1726243616623`,
          cardText: {
            span: "Foundation Herbs",
            h2: `Herb Product 12`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/herbs/12`,
        },
        {
          id: 14,
          y: -100,
          delay: 2.8,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Herbs/14.PNG?updatedAt=1726243616623`,
          cardText: {
            span: "Foundation Herbs",
            h2: `Herb Product 14`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/herbs/14`,
        },
        {
          id: 19,
          y: 100,
          delay: 3.8,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Herbs/19.PNG?updatedAt=1726243616623`,
          cardText: {
            span: "Foundation Herbs",
            h2: `Herb Product 19`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/herbs/19`,
        },

        // Spices
        {
          id: 11,
          y: 100,
          delay: 4.2,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Spices/11.PNG?updatedAt=1726243616623`,
          cardText: {
            span: "Foundation Spices",
            h2: `Spice Product 11`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/spices/11`,
        },
        {
          id: 12,
          y: -100,
          delay: 4.6,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Spices/12.PNG?updatedAt=172624361662 3`,
          cardText: {
            span: "Foundation Spices",
            h2: `Spice Product 12`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/spices/12`,
        },
        {
          id: 17,
          y: 100,
          delay: 5.0,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Spices/17.PNG?updatedAt=1726243616623`,
          cardText: {
            span: "Foundation Spices",
            h2: `Spice Product 17`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/spices/17`,
        },

        // Seeds
        {
          id: 5,
          y: 100,
          delay: 5.4,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Seeds/5.PNG?updatedAt=1726243616623`,
          cardText: {
            span: "Foundation Seeds",
            h2: `Seed Product 5`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/seeds/5`,
        },
        {
          id: 7,
          y: -100,
          delay: 5.8,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Seeds/7.PNG?updatedAt=1726243616623`,
          cardText: {
            span: "Foundation Seeds",
            h2: `Seed Product 7`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/seeds/7`,
        },
        {
          id: 11,
          y: 100,
          delay: 6.2,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Seeds/11.PNG?updatedAt=1726243616623`,
          cardText: {
            span: "Foundation Seeds",
            h2: `Seed Product 11`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/seeds/11`,
        },

        // Legumes
        {
          id: 2,
          y: 100,
          delay: 6.6,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Legumes/2.PNG?updatedAt=1726243616623`,
          cardText: {
            span: "Foundation Legumes",
            h2: `Legume Product 2`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/legumes/2`,
        },
        {
          id: 9,
          y: -100,
          delay: 7.0,
          cardImage: `https://ik.imagekit.io/pcet3dvcu/Legumes/9.PNG?updatedAt=1726243616623`,
          cardText: {
            span: "Foundation Legumes",
            h2: `Legume Product 9`,
          },
          cardDetails: {
            rating: Math.floor(Math.random() * 5) + 1, // Random rating
            reviewing: Math.floor(Math.random() * 500) + 1, // Random number of reviews
            pricing: (Math.random() * 50).toFixed(2), // Random price
          },
          button: "MORE DETAILS",
          link: `/products/legumes/9`,
        },
      ],
    },

    ThirdSec: {
      backGroundImg: thidSecImg,
      h1: "Not Sure Where To Start?",
      p1: "LET EGY HERBS GLOBAL BE YOUR GUIDE",
      p2: "Navigating the vast world of herbs , spices or plants can be overwhelming. At Egy Herbs Global, we understand the challenges businesses face in sourcing the right products. That’s why we continuously improve the quality of our offerings to simplify your journey.",
      buttonText: "TAKE A LOOK",
      buttonLink: "/products",
    },

    ForthSec: {
      title: "Thousands 5 - Star Reviews For Our Products",
      newsLetterCards: extractedData.productData.map((image, index) => ({
        id: index + 1,
        y: index % 2 === 0 ? 100 : -100,
        delay: 0.2 * (index + 1),
        cardImage: newsLetterCardImage1,
        span: "Herbs Collection",
        span2: "Shop All",
        h2: "Best Supplement Ever!",
        p: "I love this selection of herbs! I noticed a significant improvement in my agricultural yields within just a few days of use. It's like my crops are thriving like never before .",
        signature: "Kimberly P.",
      })),
      // {
      //   id: 1,
      //   cardImage: newsLetterCardImage1,
      //   span: "Herbs Collection",
      //   span2: "Shop All",
      //   h2: "Best Supplement Ever!",
      //   p: "I love this selection of herbs! I noticed a significant improvement in my agricultural yields within just a few days of use. It's like my crops are thriving like never before .",
      //   signature: "Kimberly P.",
      // },
      // {
      //   id: 2,
      //   cardVideoUrl: "https://www.youtube.com/embed/BaEzZUqmQDc",
      //   cardVideoTittle: "video",
      // },
      // {
      //   id: 3,
      //   cardImage: newsLetterCardImage2,
      //   span: "spices Collection",
      //   span2: "Shop All",
      //   h2: "Best Supplement Ever!",
      //   p: "I love this selection of herbs! I noticed a significant improvement in my agricultural yields within just a few days of use. It's like my crops are thriving like never before .",
      //   signature: "Kimberly P.",
      // },
      // {
      //   id: 4,
      //   cardVideoUrl: "https://www.youtube.com/embed/BaEzZUqmQDc",
      //   cardVideoTittle: "video",
      // },
      // {
      //   id: 5,
      //   cardImage: newsLetterCardImage3,
      //   span: "Herbs Collection",
      //   span2: "Shop All",
      //   h2: "Best Supplement Ever!",
      //   p: "I love this selection of herbs! I noticed a significant improvement in my agricultural yields within just a few days of use. It's like my crops are thriving like never before .",
      //   signature: "Kimberly P.",
      // },
      // {
      //   id: 6,
      //   cardVideoUrl: "https://www.youtube.com/embed/BaEzZUqmQDc",
      //   cardVideoTittle: "video",
      // },
      // {
      //   id: 7,
      //   cardImage: newsLetterCardImage4,
      //   span: "Herbs Collection",
      //   span2: "Shop All",
      //   h2: "Best Supplement Ever!",
      //   p: "I love this selection of herbs! I noticed a significant improvement in my agricultural yields within just a few days of use. It's like my crops are thriving like never before .",
      //   signature: "Kimberly P.",
      // },
    },

    FifhSec: {
      backGroundImg: fifthSecImg,
      backGroundStiker: fifthSecStiker,
      text: {
        h1: '"Everything starts in the soil.”',
        paragraph: {
          p1: "HEALTHY SOIL, HEALTHY HERBS",
          p2: "At Egy Herbs Global, we know that the foundation of high-quality herbs begins with healthy, nutrient-rich soil. Our partnership with local farmers is built on this shared belief, ensuring that our products are grown sustainably and with care.",
          p3: "We are proud to support eco-friendly agricultural practices that prioritize soil health, environmental responsibility, and fair treatment of workers, guaranteeing that every herb we export meets the highest standards.",
        },
      },
      button: "UNDERSTAND OUR IMPACT",
    },

    SixthSec: {
      backGroundImg: sixthSecImg,
      text: {
        h1: "Our Herbs & Spices Collection",
        paragraph: {
          p1: "PREMIUM HERBAL SOLUTIONS",
          p2: "Elevate your product offerings with our Herbs and spices Collection by Egy Herbs Global, featuring a curated selection of our finest dried herbs. These premium herbs are ideal for businesses in industries ranging from food and beverages to cosmetics and pharmaceuticals.",
          p3: "DETAILS",
          // p4: "Explore our range of high-quality herbs, designed to meet the needs of various industries, from food and beverages to health and wellness.",
          span: "Bundle & save 20% on this ritual only.",
        },
      },
      button: "VIEW PRODUCTS",
    },

    SeventhSec: {
      title: "Why Egy Herbs Global ?",
      Cards: [
        {
          id: 1,
          cardImage: seventhcardIcon1,
          tittle: "From Seed to Supply",
          p: "Quality starts with us. Egy Herbs Global partners with local farmers to deliver premium herbs and spices. We ensure every step from seed to final product meets the highest standards.",
          button: "Kimberly P.",
        },
        {
          id: 2,
          cardImage: seventhcardIcon2,
          tittle: "From Farm to Table",
          p: "We source the finest herbs and spices from local farms and process them to retain their quality. Ideal for food, cosmetics, and pharmaceuticals.",
          button: "Kimberly P.",
        },
        {
          id: 3,
          cardImage: seventhcardIcon3,
          tittle: "Crafted for Quality",
          p: "Our herbs and spices are meticulously processed to preserve their natural essence. Tailored solutions for various industries.",
          button: "Kimberly P.",
        },
        {
          id: 4,
          cardImage: seventhcardIcon4,
          tittle: "Globally Delivered",
          p: "Committed to sustainability, we source and deliver top-quality herbs and spices worldwide, ensuring exceptional standards.",
          button: "Kimberly P.",
        },
      ],
    },

    EighththSec: {
      title: {
        span1: "OUR BLOG",
        span2: "Seeds of Knowledge",
      },
      Cards: [
        {
          id: 1,
          cardImage: EighthSecImg1,
          spans: {
            span1: "EDUCATION",
            span2: "PRODUCTS",
          },
          tittle: "What Are Dried Herbs? Definition, Uses, and Benefits",
          p: "Many industries are familiar with the value of fresh herbs, but have you explored the benefits of dried herbs? These herbs maintain their flavor and potency, offering versatility across various sectors like food, cosmetics, and pharmaceuticals.",
          button: "Read More",
        },
        {
          id: 2,
          cardImage: EighthSecImg2,
          spans: {
            span1: "EDUCATION",
            span2: "PRODUCTS",
          },
          tittle: "The Versatility of Spices: Uses Across Industries",
          p: "Spices are more than just flavor enhancers. From food manufacturing to cosmetics and natural remedies, spices like cumin, coriander, and turmeric play vital roles across various industries. Learn how Egy Herbs Global supplies premium spices tailored to your business needs.",
          button: "Read More",
        },
      ],
    },

    NinthSec: {
      backgroundImage: NinthSecImg,
      spans: {
        span1: "DISCOVER THE EGHGL. DIFFERENCE",
        span2: "Empowering Your Business with Premium Herbs and Spices",
      },
      p: "Since 1987, Egy Herbs Global has been dedicated to delivering top-quality herbs and spices, ensuring your success in the global market.",
      button: "LEARN MORE",
      link: "/about",
    },

    TenthSec: [
      {
        id: 1,
        cardImage: tenththcardIcon1,
        title: "Founder-Driven",
        p: "leadership ensures premium quality.",
      },
      {
        id: 2,
        cardImage: tenththcardIcon2,
        title: "Global Reach",
        p: "Decades of experience in the international market.",
      },
      {
        id: 3,
        cardImage: tenththcardIcon3,
        title: "Quality Guaranteed",
        p: "Stringent checks for purity and safety.",
      },
      {
        id: 4,
        cardImage: tenththcardIcon4,
        title: "From Farm to Partner",
        Link: "Discover Our Process",
        p: "See how we turn local herbs into global excellence.",
      },
    ],
  },
];

// products page data

// Export the products data
export const ProductsData = [
  {
    Hero: {
      backgroundImage: productHeroSecImg,
      spans: {
        span2: "All Products",
      },
      p: "Discover our high-quality, plant-based herbs and spices you can trust. Each formula is crafted to ensure purity, potency, and integrity.",
    },
    SecondSec: {
      Type: {
        type1: "Herbs",
        type2: "Spices",
        type3: "Seeds",
        type4: "Legumes",
      },
      ProductCardData: extractedData.productData.map((productCard, index) => ({
        id: index + 1,
        cardImage: productCard.url,
        h2: productCard.name,
        category: productCard.category,
        rating: productCard.rating,
        reviews: productCard.reviews,
        button: "MORE DETAILS",
      })),
    },

    ThirdSec: {
      Left: {
        backgroundImage: productThirdSecImg,
      },
      Right: {
        h2: "Certified B Corporation",
        p: "Gaia Herbs is proud to be a Certified B Corporation®, actively working to build a more inclusive and sustainable economy. Certified B Corporations are businesses that meet the highest standards of verified social and environmental performance, public transparency, and legal accountability to balance profit and purpose.",
      },
    },
    ForthSec: {
      Right: {
        backgroundImage: productForthSecImg,
      },
      Left: {
        h2: "Why Choose Egy Herbs Global?",
        p1: "Egy Herbs For Import & Export is a family owned company; it was founded in 1986 as a grower and producer of Herbs, Spices and Seeds, then we started manufacturing our own production to be distributed by the local exporting agents and offices",
        p2: "afterwards we decided to export our own production ourselves as we are known all over Egypt that we are the specialists in these fields to present Good Quality for competitive prices.",
      },
    },
  },
];

export const AboutData = [
  {
    Hero: {
      backGroundImage: aboutHeroSecImg,
      spans: {
        span2: "About Egy Herbs Global",
      },
      p: "Since 1987, Egy Herbs Global has been committed to delivering top-quality herb, spice, and seed solutions.",
    },
    SecondSec: {
      spans: {
        span2: "Connecting People, Plants & Planet to Create Healing",
      },
      p: "Since Egy Herbs Global was founded in 1987, we have remained true to our purpose: connecting people, plants, and the planet to create healing. We believe that herbs, spices, and seeds hold the wisdom needed to foster health and well-being.",
    },
    ThirdSec: [
      {
        backGroundImg: about1,
        h1: "Management Team",
        P: "At Egy Herbs Global, we have built a team of experts who specialize in the production and export of high-quality dried herbs, spices, and seeds. Our leadership team combines decades of experience with a commitment to delivering top-notch products to our global partners.",
        P2: "Mohamed Ahmed, Founder and CEO, has guided the growth of Egy Herbs Global from a local supplier to a trusted international exporter, delivering high-quality dried herbs, spices, and seeds to businesses worldwide.",
        direction: "rtl",
        btn: "Read More",
      },
      {
        backGroundImg: about2,
        h1: "Our Commitment",
        P: "As a trusted B2B partner, Egy Herbs Global is dedicated to offering only the finest dried herbs, spices, and seeds. We prioritize quality at every stage — from working closely with local farmers to ensuring that our products meet the specific needs of our business clients across various industries.",
        direction: "ltr",
        btn: "Read More",
      },
      {
        backGroundImg: about3,
        h1: "Sourcing and Sustainability",
        P: "We source our herbs, spices, and seeds directly from Egyptian farms, maintaining strong relationships with growers to ensure both consistency and quality. Our commitment to sustainability means working with eco-friendly practices to provide superior products that are responsibly produced.",
        direction: "rtl",
        btn: "Read More",
      },
      {
        backGroundImg: about4,
        h1: "Organic and Natural Products",
        P: "We offer a wide range of herbs, spices, and seeds that are grown naturally, without the use of harmful chemicals. For businesses seeking organic ingredients, Egy Herbs Global ensures that our products meet the highest standards.",
        direction: "ltr",
        btn: "Read More",
      },
      {
        backGroundImg: about5,
        h1: "Our Growers",
        P: "From a local supplier in Egypt to an international exporter, Egy Herbs Global has expanded rapidly over the past 30 years. Our expertise and ability to meet global standards have made us a trusted B2B partner, serving clients worldwide with high-quality dried herbs, spices, and seeds.",
        direction: "rtl",
        btn: "Read More",
      },
      {
        backGroundImg: about6,
        h1: "Why Choose Us?",
        P: "Expertise: With over 30 years in the industry, we know the ins and outs of growing, processing, and exporting premium herbs, spices, and seeds.",
        P2: "Tailored Solutions: We work closely with our B2B clients to provide customized product solutions that fit their specific manufacturing and market demands.",
        P3: "Global Reach: Our company is equipped to meet the demands of international markets, ensuring efficient logistics and on-time deliveries.",
        direction: "ltr",
        btn: "Read More",
      },
      {
        backGroundImg: about7,
        h1: "Quality Control and Certifications",
        P: "We take quality seriously at Egy Herbs Global. Our herbs, spices, and seeds undergo rigorous quality control processes to ensure they meet international standards. Each batch is tested for purity and safety, giving you peace of mind when choosing us as your supplier.",
        direction: "rtl",
        btn: "Read More",
      },
      {
        backGroundImg: about8,
        h1: "What We Offer",
        P: "At Egy Herbs Global, we are proud to be a trusted B2B partner for companies around the world seeking reliable suppliers of an extensive range of premium dried herbs, spices, and seeds. Our broad product portfolio meets the demands of industries like food and beverages, cosmetics, pharmaceuticals, and more.",
        P2: "Aromatic Herbs: From basil, thyme, and oregano to sage and rosemary, we offer a wide array of herbs ideal for culinary, health, and wellness products.",
        P3: "Medicinal Plants: We provide herbs like chamomile, echinacea, and peppermint, used extensively in natural remedies and holistic health products.",
        P4: "Spices: Our selection of spices, including black pepper, turmeric, cumin, and coriander, caters to food manufacturers and the global spice trade.",
        P5: "Seeds and Roots: From fennel and anise seeds to ginger and licorice roots, our offerings are versatile ingredients for multiple industries.",
        P6: "Specialty Products: We also supply unique herbs, spices, and botanicals for niche markets, ensuring we meet your specific industry requirements.",
        direction: "ltr",
        btn: "Read More",
      },
      {
        backGroundImg: about9,
        h1: "Our Expertise",
        P: "With decades of experience working closely with local farmers, Egy Herbs Global guarantees a consistent supply of premium dried herbs, spices, and seeds. We manage every step of the process, ensuring they meet the highest industry standards for export.",
        direction: "rtl",
        btn: "Read More",
      },
      {
        backGroundImg: about10,
        h1: "Our Mission",
        P: "Our mission is to provide high-quality, sustainably sourced products that are customized to your business’s needs, ensuring that your manufacturing processes are efficient and consistent.",
        direction: "ltr",
        btn: "Read More",
      },
    ],
  },
];

export const SupplyChainData = [
  {
    Hero: {
      backGroundImage: supplyChainForthSecImg,
      spans: {
        span2: "Our Supply Chain",
      },
      p: "Explore our commitment to quality and efficiency. Reach out for any inquiries or to learn more about our trusted supply chain.",
    },

    ThirdSec: [
      {
        backGroundImg: farmingImage,
        h1: "Farming",
        p2: "Our journey starts in the fields where our knowledgeable farmers cultivate our medicinal and aromatic plants. We employ sustainable farming practices that not only yield high-quality produce but also protect and enrich the environment. Our farmers carefully monitor the growth of each plant, ensuring that they are healthy and free from harmful chemicals.",

        direction: "ltr",
        btn: "Read More",
      },
      {
        backGroundImg: dryingImage,
        h1: "Drying",
        p2: "Once harvested, the plants undergo a precise drying process. This step is essential for preserving the plants’ medicinal properties, flavor, and aroma. We use advanced drying techniques that maintain the integrity of the plants while preventing any damage or loss of beneficial compounds.",

        direction: "rtl",
        btn: "Read More",
      },
      {
        backGroundImg: procesingImage,
        h1: "Processing",
        p2: "The dried plants are then sent to our processing facilities. Here, we use a combination of traditional methods and modern technology to convert the raw plants into various forms like powders, extracts, or whole dried herbs. Our processing methods are designed to preserve the potency of the plants while ensuring safety and cleanliness.",

        direction: "ltr",
        btn: "Read More",
      },
      {
        backGroundImg: packagingImage,
        h1: "Packaging",
        p2: "We understand that proper packaging is crucial in maintaining the quality of our products and ensuring they reach our customers in excellent condition. Our packaging process involves using high-quality materials and techniques to protect the products from damage during transit. We also focus on aesthetics, ensuring that the packaging is as appealing as the product inside.",

        direction: "rtl",
        btn: "Read More",
      },
      {
        backGroundImg: deliverygImage,
        h1: "Delivery",
        p2: "Our commitment to quality extends to our delivery process. We partner with reliable logistics companies to ensure that our products reach our customers promptly and in perfect condition. We provide both domestic and international shipping, tracking each order closely to ensure a smooth delivery process.",

        direction: "ltr",
        btn: "Read More",
      },
      {
        backGroundImg: customergImage,
        h1: "Customer Service",
        p2: "We believe that our relationship with our customers extends beyond the sale of our products. Our dedicated customer service team is always ready to assist with any queries or concerns. Whether you need help with an order, have a question about our products, or require after-sales support, we’re here to help. Our goal is to ensure a seamless and satisfying experience for every customer.",

        direction: "rtl",
        btn: "Read More",
      },
    ],

    ForthSec: {
      Right: {
        backgroundImage: supplyChainForthSecImg,
      },
      Left: {
        h2: "Why Choose Egy Herbs Global ?",
        p1: "Egy Herbs For Import & Export is a family-owned company; it was founded in 1986 as a grower and producer of Herbs, Spices and Seeds, then we started manufacturing our own production to be distributed by the local exporting agents and offices",
        p2: "afterwards we decided to export our own production ourselves as we are known all over Egypt that we are the specialists in these fields to present Good Quality for competitive prices.",
      },
    },
  },
];

export const ContactData = [
  {
    Hero: {
      backgroundImage: contactHeroSecImg,
      spans: {
        span2: "Contact & Support",
      },
      p: "We value your feedback and inquiries! Reach out to us with any questions or for more information using the details below.",
    },
    SecondSec: {
      backgroundImage: contactSecondSecImg,
      spans: {
        span2: "Connecting People, Plants & Planet to Create Healing",
      },
      p: "Since Gaia Herbs was just a seedling in 1987, we have remained true to our purpose: connecting people, plants, and planet to create healing. We knew then what many are coming to realize now—plants and people evolved together, that we are inextricably linked, and that plants hold the wisdom we need to heal.",
    },
    ThirdSec: {
      backGroundImg: contactThirdSecImg,
      h1: "Helpful Resources",
      p2: "Below are some resources that may help but always feel free to contact us directly!",
      navLinks: [
        { text: "Shop Products", link: "/products" },
        { text: "Herb Reference Guide", link: "/" },
        { text: "Herbal FAQ", link: "/" },
        { text: "Where to Buy", link: "/" },
        { text: "International Shipping", link: "/" },
      ],
    },
    ForthSec: {
      Right: {
        backgroundImage: contactForthSecImg,
      },
      Left: {
        h2: "Why Choose Egy Herbs Global ?",
        p1: "Egy Herbs For Import & Export is a family-owned company; it was founded in 1986 as a grower and producer of Herbs, Spices and Seeds, then we started manufacturing our own production to be distributed by the local exporting agents and offices",
        p2: "afterwards we decided to export our own production ourselves as we are known all over Egypt that we are the specialists in these fields to present Good Quality for competitive prices.",
      },
    },
  },
];

// FOOTER SECTION
export const footerData = {
  logoSrc: "/logo07.png",
  address: "Egy Herbs Global, Egypt, Fayoum",
  copyright: "© 2024 Egy Herbs Global. All Rights Reserved",
  certification: {
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    imgSrc: "/certified_72x.png",
  },
  socialLinks: [
    { icon: "BiLogoFacebook", href: "/" },
    { icon: "BiLogoTwitter", href: "/" },
    { icon: "BiLogoInstagram", href: "/" },
    { icon: "BiLogoLinkedin", href: "/" },
  ],
  linkSections: [
    {
      title: "Link Title 1",
      links: [
        { title: "Lorem ipsum dolor", url: "/" },
        { title: "Lorem ipsum dolor sit.", url: "/" },
        { title: "Lorem ipsum dolor sit.", url: "/" },
      ],
    },
    {
      title: "Link Title 2",
      links: [
        { title: "Lorem ipsum dolor", url: "/" },
        { title: "Lorem ipsum dolor sit.", url: "/" },
        { title: "Lorem ipsum dolor sit.", url: "/" },
      ],
    },
    {
      title: "Link Title 3",
      links: [
        { title: "Lorem ipsum dolor", url: "/" },
        { title: "Lorem ipsum dolor sit.", url: "/" },
        { title: "Lorem ipsum dolor sit.", url: "/" },
      ],
    },
    {
      title: "Link Title 4",
      links: [
        { title: "Lorem ipsum dolor", url: "/" },
        { title: "Lorem ipsum dolor sit.", url: "/" },
        { title: "Lorem ipsum dolor sit.", url: "/" },
      ],
    },
  ],
};
