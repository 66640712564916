import React from "react";
import { Link } from "react-router-dom";

export default function Form() {
  return (
    <div className="flex justify-center items-center px-5 lg:px-20 h-screen bg-white">
      <div className="w-full flex flex-col items-center gap-5">
        <span className="text-4xl w-full font-tfn text-black">
          Send A Message
        </span>
        <form className="w-full flex flex-col gap-5 justify-between">
          {/* Select Box */}
          <div className="w-full">
            <input
              type="text"
              className="w-full py-4 px-5 border border-black rounded-md placeholder-gray-600"
              placeholder="Please Select How We Can Help You"
              required
            />
          </div>

          {/* Name Input Row */}
          <div className="flex flex-col lg:flex-row gap-5">
            <input
              type="text"
              className="w-full lg:w-1/2 py-4 px-5 border border-black rounded-md placeholder-gray-600"
              placeholder="First Name (required)"
              required
            />
            <input
              type="text"
              className="w-full lg:w-1/2 py-4 px-5 border border-black rounded-md placeholder-gray-600"
              placeholder="Last Name (optional)"
            />
          </div>

          {/* Email Input */}
          <div className="w-full">
            <input
              type="email"
              className="w-full py-4 px-5 border border-black rounded-md placeholder-gray-600"
              placeholder="Email Address (required)"
              required
            />
          </div>

          {/* Textarea */}
          <div className="w-full">
            <textarea
              className="w-full h-40 py-4 px-5 border border-black rounded-md placeholder-gray-600"
              placeholder="How Can We Help You?"
              required
            ></textarea>
          </div>

          {/* Submit Button */}
          <div className="flex">
            <Link
              to="/"
              className="bg-[#205c40] text-white py-3 px-10 rounded-md text-lg"
            >
              Submit
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
