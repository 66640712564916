import React from "react";
import { SupplyChainData } from "../../data";
import NinthSec from "../NinthSec";
import SecSec from "../SecSec";
import SupplyThirdSec from "./Third-Sec/SupplyThirdSec";

export default function SupplyChain() {
  const content = SupplyChainData[0];

  return (
    <div className="flex flex-col gap-5 lg:gap-14">
      <NinthSec
        backgroundImage={content.Hero.backGroundImage}
        spans={content.Hero.spans}
        p={content.Hero.p}
      />
      <div className="flex flex-col gap-5 lg:gap-14">
        <SecSec />
        {content.ThirdSec.map((element, i) => (
          <SupplyThirdSec
            key={i}
            backGroundImg={element.backGroundImg}
            p1={element.p1}
            h1={element.h1}
            p2={element.p2}
            link={element.buttonLink}
            text={element.buttonText}
            navLinks={element.navLinks}
            direction={element.direction}
          />
        ))}
      </div>
    </div>
  );
}
