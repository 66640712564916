import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function ThirdSec({
  backGroundImg,
  p1,
  h1,
  p2,
  link,
  text,
  navLinks,
  direction,
  btn,
}) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      controls.start("show");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <div className="third-sec bg-gradient-to-r from-[#f0eee3] py-12 px-6 to-[#e5d3fb] flex justify-center items-center h-fit">
      <div
        className="flex flex-col lg:flex-row justify-center items-center gap-5 h-[80vh] p-0 lg:gap-24 lg:px-10"
        style={{ direction: direction }}
        ref={ref}
      >
        <motion.div
          style={{
            background: `url(${backGroundImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="left-div relative rounded-lg h-1/2 lg:h-full w-full lg:w-1/2"
          variants={{
            hidden: { opacity: 0 },
            show: { opacity: 1 },
          }}
          initial="hidden"
          animate={controls}
          transition={{
            duration: 1,
            ease: "easeOut",
          }}
        ></motion.div>
        <div
          className="right-div-container flex h-1/2 lg:h-full flex-col justify-evenly items-center lg:items-start w-full lg:w-1/3"
          ref={ref}
        >
          {p1 && (
            <motion.p
              className="top font-markPro tracking-wider text-sm text-center lg:text-start"
              variants={{
                hidden: { opacity: 0, x: 100 },
                show: { opacity: 1, x: 0 },
              }}
              initial="hidden"
              animate={controls}
              transition={{
                duration: 0.5,
                ease: "easeOut",
                delay: 0.4,
              }}
            >
              {p1}
            </motion.p>
          )}
          <motion.h1
            className="font-tfn text-4xl text-[#16402d] tex-center lg:text-start"
            variants={{
              hidden: { opacity: 0, x: 200 },
              show: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={controls}
            transition={{
              duration: 0.5,
              ease: "easeOut",
            }}
          >
            {h1}
          </motion.h1>
          <motion.p
            className="paragraph text-md leading-[26px] font-markPro text-[#524f4f] text-center lg:text-start"
            variants={{
              hidden: { opacity: 0, x: 200 },
              show: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={controls}
            transition={{
              duration: 0.5,
              ease: "easeOut",
              delay: 0.4,
            }}
          >
            {p2}
          </motion.p>
          {text && (
            <Link to={link} className="btn">
              {text}
            </Link>
          )}
          {navLinks && (
            <ul>
              {navLinks.map(({ text, link }, index) => (
                <li key={index}>
                  <Link to={link} className="contact-third-link">
                    {text}
                  </Link>
                </li>
              ))}
            </ul>
          )}
          {btn && (
            <Link to="/" className="link">
              {btn}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
