import React from "react";

export default function ContactThirdSec() {
  return (
    <div className="h-svh flex flex-col lg:flex-row justify-center items-center">
      {/* Left Section */}
      <div className="flex flex-col justify-center gap-10 items-center bg-[#205c40] text-white w-full lg:w-1/2 h-full p-10 lg:p-0">
        <div className="flex flex-col justify-between w-full lg:w-1/2 gap-5">
          <p className="font-bold">Customer & Retailer Support</p>
          <p>
            We're here to assist you! Reach out to us during weekdays, and we’ll
            respond as soon as we can. Please note, we do not have a physical
            retail location.
          </p>
        </div>

        <div className="flex flex-col justify-between gap-5 items-center w-full lg:w-1/2">
          <div className="w-full flex justify-between items-center">
            <p>Phone</p>
            <span>+201222297357</span>
          </div>
          <div className="w-full flex justify-between items-center">
            <p>Email</p>
            <span>info@egyherbsglobal.com</span>
          </div>
          <div className="w-full flex justify-between items-center">
            <p>Support</p>
            <span>support@egyherbsglobal.com</span>
          </div>
          <div className="w-full flex justify-between items-center">
            <p>Sales</p>
            <span>sales@egyherbsglobal.com</span>
          </div>
          <div className="w-full flex justify-between items-center">
            <p>Address</p>
            <span>Egypt, Fayoum, Ibshaway – Al Nazla</span>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex flex-col justify-center gap-10 items-center bg-white w-full lg:w-1/2 h-full p-10 lg:p-0">
        <div className="flex flex-col justify-between w-full lg:w-1/2">
          <p className="font-bold">Contact Us</p>
        </div>
        <div className="flex flex-col justify-between gap-5 items-center w-full lg:w-1/2">
          <div className="w-full flex justify-between items-center">
            <p>New Orders</p>
            <span>Option 1</span>
          </div>
          <div className="w-full flex justify-between items-center">
            <p>Product Inquiries</p>
            <span>Option 2</span>
          </div>
          <div className="w-full flex justify-between items-center">
            <p>Retail Partner Support</p>
            <span>Option 3</span>
          </div>
          <div className="w-full flex justify-between items-center">
            <p>General Inquiries</p>
            <span>Option 4</span>
          </div>
        </div>
      </div>
    </div>
  );
}
