// useImagesLoaded.js
import { useState, useEffect } from "react";

const useImagesLoaded = (imageUrls) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let loadedImagesCount = 0;
    const totalImages = imageUrls.length;

    const handleImageLoad = () => {
      loadedImagesCount += 1;
      if (loadedImagesCount === totalImages) {
        setIsLoaded(true);
      }
    };

    if (totalImages === 0) {
      setIsLoaded(true);
      return;
    }

    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = handleImageLoad;
      img.onerror = handleImageLoad; // Consider image load errors as successful completion
    });

    // Clean up in case component unmounts
    return () => {
      setIsLoaded(true);
    };
  }, [imageUrls]);

  return { isLoaded };
};

export default useImagesLoaded;
