// SecondSec.js
import React, { useState } from "react";
import { BiSolidStar } from "react-icons/bi";
import useImagesLoaded from "../../../useImagesLoaded";

export default function SecondSec({ Type, ProductCardData }) {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("All Products");

  const toggleMenu = () => {
    setIsFilterMenuOpen(!isFilterMenuOpen);
  };

  const StarRating = ({ rating }) => {
    const stars = [];
    for (let i = 0; i < Math.round(rating); i++) {
      stars.push(<BiSolidStar style={{ color: "#eba937" }} key={i} />);
    }
    return <span className="rating flex gap-1">{stars}</span>;
  };

  const filteredProducts = ProductCardData.filter(
    (product) =>
      selectedFilter === "All Products" || product.category === selectedFilter
  );

  const { isLoaded } = useImagesLoaded(
    ProductCardData.map((product) => product.cardImage)
  );

  return (
    <>
      {!isLoaded && (
        <div className="loading-screen">
          {/* Your loading spinner or animation here */}
          <p>Loading...</p>
        </div>
      )}
      <div className="p-6 flex overflow-hidden gap-5 flex-col lg:flex-row justify-between">
        <div className="filters w-full p-5 lg:w-fit flex flex-col gap-4">
          <button
            onClick={toggleMenu}
            className={`w-full text-xl lg:hidden p-3 rounded-md transition-colors duration-300 ${
              isFilterMenuOpen
                ? "bg-transparent border"
                : "bg-[#16402d] text-white"
            }`}
          >
            {isFilterMenuOpen ? "Hide Filters" : "Show Filters"}
          </button>
          <nav
            className={`lg:flex justify-between flex-col gap-2 lg:gap-4 w-full ${
              isFilterMenuOpen ? "flex" : "hidden"
            }`}
          >
            <button
              onClick={() => setSelectedFilter("All Products")}
              className={`lg:w-[120px] text-start p-3 font-medium rounded-md transition-colors duration-300 ${
                selectedFilter === "All Products"
                  ? "bg-[#356a4c]"
                  : "bg-white text-[#356a4c]"
              } border border-[#356a4c]`}
            >
              All Products
            </button>
            <button
              onClick={() => setSelectedFilter(Type.type1)}
              className={`lg:w-[120px] text-start p-3 font-medium rounded-md transition-colors duration-300 ${
                selectedFilter === Type.type1
                  ? "bg-[#356a4c]"
                  : "bg-white text-[#356a4c]"
              } border border-[#356a4c]`}
            >
              {Type.type1}
            </button>
            <button
              onClick={() => setSelectedFilter(Type.type2)}
              className={`lg:w-[120px] text-start p-3 font-medium rounded-md transition-colors duration-300 ${
                selectedFilter === Type.type2
                  ? "bg-[#356a4c]"
                  : "bg-white text-[#356a4c]"
              } border border-[#356a4c]`}
            >
              {Type.type2}
            </button>
            <button
              onClick={() => setSelectedFilter(Type.type3)}
              className={`lg:w-[120px] text-start p-3 font-medium rounded-md transition-colors duration-300 ${
                selectedFilter === Type.type3
                  ? "bg-[#356a4c]"
                  : "bg-white text-[#356a4c]"
              } border border-[#356a4c]`}
            >
              {Type.type3}
            </button>
            <button
              onClick={() => setSelectedFilter(Type.type4)}
              className={`lg:w-[120px] text-start p-3 font-medium rounded-md transition-colors duration-300 ${
                selectedFilter === Type.type4
                  ? "bg-[#356a4c]"
                  : "bg-white text-[#356a4c]"
              } border border-[#356a4c]`}
            >
              {Type.type4}
            </button>
          </nav>
        </div>

        <div className="product-cards-container flex flex-wrap justify-center gap-10 transition-opacity duration-300">
          {filteredProducts.map((product, index) => (
            <div
              key={index}
              onMouseEnter={() => setHoveredCardIndex(index)}
              onMouseLeave={() => setHoveredCardIndex(null)}
              className="transition-all cursor-pointer p-2 lg:max-w-xs duration-300 flex flex-col justify-between gap-5"
            >
              <img
                src={product.cardImage}
                alt={product.h2}
                className="product-image"
              />
              <h2 className="text-xl ml-2 mt-2">{product.h2}</h2>
              <p>{product.category}</p>
              <div className="flex gap-4 ml-2 items-center">
                <StarRating rating={product.rating} />
                <span className="reviewing">{product.reviews} Reviews</span>
              </div>
              <button
                className={`bg-[#356a4c] text-white mx-2 outline-none border-none rounded-md font-thin p-2 transition-opacity duration-300 ${
                  hoveredCardIndex === index ? "lg:opacity-100" : "lg:opacity-0"
                }`}
              >
                {product.button}
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
